var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1"},[_c('div',{staticClass:"auth-inner"},[_c('v-card',{staticClass:"auth-card"},[(_vm.method === 'yubikey')?_c('v-card-title',{staticClass:"d-flex align-center justify-center py-5"},[_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":"/"}},[_c('v-img',{staticClass:"mb-6",attrs:{"max-width":"150","contain":"","src":_vm.app_logo}})],1)],1):_c('div',{staticClass:"my-8"},[(!_vm.qr_string)?_c('div',{staticStyle:{"height":"280px"}}):_c('qrcode-vue',{staticClass:"text-center",attrs:{"value":_vm.qr_string,"size":"280","level":"M"}})],1),_c('v-card-text',[_c('p',{staticClass:"text-2xl font-weight-semibold text--primary mb-2"},[_vm._v(" "+_vm._s(_vm.$t('page.twofactor.activate_twofactor'))+" ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.method === 'google-authenticator' ? _vm.$t('page.twofactor.activate_authenticator_description') : _vm.$t('page.twofactor.activate_yubikey_description'))+" ")]),(_vm.method == 'google-authenticator')?[_c('v-row',{staticClass:"my-1"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":"","nudge-top":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":"https://www.microsoft.com/en-us/security/mobile-authenticator-app","target":"_blank"}},'a',attrs,false),on),[_c('v-avatar',{staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"50px","color":"primary"}},[_c('v-img',{attrs:{"src":require("@/assets/images/authenticators/microsoft.png")}})],1)],1)]}}],null,false,1671637967)},[_c('span',[_vm._v(_vm._s(_vm.$t('page.profile.more_info', { item: 'Microsoft Authenticator' })))])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":"","nudge-top":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":"https://googleauthenticator.net/","target":"_blank"}},'a',attrs,false),on),[_c('v-avatar',{staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"50px","color":"primary"}},[_c('v-img',{attrs:{"src":require("@/assets/images/authenticators/google.png")}})],1)],1)]}}],null,false,2346683103)},[_c('span',[_vm._v(_vm._s(_vm.$t('page.profile.more_info', { item: 'Google Authenticator' })))])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":"","nudge-top":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":"https://authy.com/download/","target":"_blank"}},'a',attrs,false),on),[_c('v-avatar',{staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"50px","color":"primary"}},[_c('v-img',{attrs:{"src":require("@/assets/images/authenticators/authy.png")}})],1)],1)]}}],null,false,2705734511)},[_c('span',[_vm._v(_vm._s(_vm.$t('page.profile.more_info', { item: 'Authy' })))])])],1)],1)]:_vm._e()],2),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"disabled":_vm.loading,"label":_vm.$t('label.code'),"error-messages":_vm.$t(_vm.code.error, { field: _vm.$t('label.code') }),"outlined":"","autofocus":"","hide-details":"auto","type":_vm.method === 'yubikey' ? 'password' : ''},model:{value:(_vm.code.model),callback:function ($$v) {_vm.$set(_vm.code, "model", $$v)},expression:"code.model"}}),_c('v-text-field',{staticClass:"mb-6",attrs:{"disabled":_vm.loading,"label":_vm.$t('label.secondary_email'),"error-messages":_vm.$t(_vm.secondary_email.error, { field: _vm.$t('label.secondary_email') }),"placeholder":_vm.blurred_second_email,"persistent-placeholder":"","outlined":"","hide-details":"auto"},model:{value:(_vm.secondary_email.model),callback:function ($$v) {_vm.$set(_vm.secondary_email, "model", $$v)},expression:"secondary_email.model"}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.mdiHelp))])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('page.twofactor.secondary_email_description')))])])],1)],2),_c('v-btn',{attrs:{"type":"submit","color":"primary","disabled":_vm.loading,"loading":_vm.loading,"block":""},on:{"click":_vm.activateTwofactor}},[_vm._v(" "+_vm._s(_vm.$t('page.twofactor.activate_twofactor'))+" ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"secondary","outlined":"","disabled":_vm.loading,"block":""},on:{"click":_vm.swapMethod}},[_vm._v(" "+_vm._s(_vm.method === 'google-authenticator' ? _vm.$t('page.twofactor.use_yubikey') : _vm.$t('page.twofactor.use_authenticator'))+" ")])],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-center align-center"},[_c('v-btn',{attrs:{"color":"secondary","text":"","disabled":_vm.loading,"block":""},on:{"click":_vm.cancel}},[_c('v-icon',{attrs:{"size":"24","color":"primary"}},[_vm._v(" "+_vm._s(_vm.arrow_left_icon)+" ")]),_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")],1)],1)],1)],1),_c('img',{staticClass:"auth-mask-bg",attrs:{"height":"190","src":require(("@/assets/images/misc/mask-" + (_vm.$vuetify.theme.dark ? 'dark' : 'light') + ".png"))}})])}
var staticRenderFns = []

export { render, staticRenderFns }