<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title v-if="method === 'yubikey'" class="d-flex align-center justify-center py-5">
          <router-link to="/" class="d-flex align-center">
            <v-img class="mb-6" max-width="150" contain :src="app_logo"></v-img>
          </router-link>
        </v-card-title>
        <div class="my-8" v-else>
          <div v-if="!qr_string" style="height: 280px"></div>
          <qrcode-vue v-else class="text-center" :value="qr_string" size="280" level="M" />
        </div>

        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            {{ $t('page.twofactor.activate_twofactor') }}
          </p>
          <p class="mb-2">
            {{
              method === 'google-authenticator'
                ? $t('page.twofactor.activate_authenticator_description')
                : $t('page.twofactor.activate_yubikey_description')
            }}
          </p>
          <template v-if="method == 'google-authenticator'">
            <v-row class="my-1">
              <v-col cols="auto">
                <v-tooltip top nudge-top="10">
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      href="https://www.microsoft.com/en-us/security/mobile-authenticator-app"
                      target="_blank"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-avatar size="50px" color="primary" class="v-avatar-light-bg primary--text">
                        <v-img src="@/assets/images/authenticators/microsoft.png"></v-img>
                      </v-avatar>
                    </a>
                  </template>
                  <span>{{ $t('page.profile.more_info', { item: 'Microsoft Authenticator' }) }}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="auto">
                <v-tooltip top nudge-top="10">
                  <template v-slot:activator="{ on, attrs }">
                    <a href="https://googleauthenticator.net/" target="_blank" v-bind="attrs" v-on="on">
                      <v-avatar size="50px" color="primary" class="v-avatar-light-bg primary--text">
                        <v-img src="@/assets/images/authenticators/google.png"></v-img>
                      </v-avatar>
                    </a>
                  </template>
                  <span>{{ $t('page.profile.more_info', { item: 'Google Authenticator' }) }}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="auto">
                <v-tooltip top nudge-top="10">
                  <template v-slot:activator="{ on, attrs }">
                    <a href="https://authy.com/download/" target="_blank" v-bind="attrs" v-on="on">
                      <v-avatar size="50px" color="primary" class="v-avatar-light-bg primary--text">
                        <v-img src="@/assets/images/authenticators/authy.png"></v-img>
                      </v-avatar>
                    </a>
                  </template>
                  <span>{{ $t('page.profile.more_info', { item: 'Authy' }) }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
        </v-card-text>

        <v-card-text>
          <v-form @submit.prevent>
            <v-text-field
              v-model="code.model"
              :disabled="loading"
              :label="$t('label.code')"
              :error-messages="$t(code.error, { field: $t('label.code') })"
              outlined
              autofocus
              hide-details="auto"
              class="mb-4"
              :type="method === 'yubikey' ? 'password' : ''"
            />

            <v-text-field
              v-model="secondary_email.model"
              :disabled="loading"
              :label="$t('label.secondary_email')"
              :error-messages="$t(secondary_email.error, { field: $t('label.secondary_email') })"
              :placeholder="blurred_second_email"
              persistent-placeholder
              outlined
              hide-details="auto"
              class="mb-6"
            >
              <template slot="append">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">{{ mdiHelp }}</v-icon>
                  </template>
                  <span> {{ $t('page.twofactor.secondary_email_description') }}</span>
                </v-tooltip>
              </template>
            </v-text-field>
            <v-btn
              @click="activateTwofactor"
              type="submit"
              color="primary"
              :disabled="loading"
              :loading="loading"
              block
            >
              {{ $t('page.twofactor.activate_twofactor') }}
            </v-btn>

            <v-btn @click="swapMethod" color="secondary" outlined :disabled="loading" block class="mt-4">
              {{
                method === 'google-authenticator'
                  ? $t('page.twofactor.use_yubikey')
                  : $t('page.twofactor.use_authenticator')
              }}
            </v-btn>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-center align-center">
          <v-btn @click="cancel" color="secondary" text :disabled="loading" block>
            <v-icon size="24" color="primary">
              {{ arrow_left_icon }}
            </v-icon>
            {{ $t('common.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />
  </div>
</template>

<script>
import { mdiChevronLeft, mdiHelp } from '@mdi/js'
import themeConfig from '@themeConfig'
import QrcodeVue from 'qrcode.vue' //eslint-disable-line

export default {
  name: 'TwoFactorActivate',
  components: {
    QrcodeVue,
  },

  beforeMount() {
    this.$http('2fa/secret')
      .then(res => {
        this.qr_string = res.data.qr_string
        this.secret = res.data.secret
      })
      .catch(() => {
        this.$router.back()
      })

    this.$http('2fa/hint_email').then(res => {
      if (res.data.blurred_second_email) {
        this.blurred_second_email = res.data.blurred_second_email
      }
    })
  },
  data: () => ({
    qr_string: undefined,
    secret: undefined,

    code: {
      model: '',
      error: '',
    },

    secondary_email: {
      model: '',
      error: '',
    },
    blurred_second_email: null,

    loading: false,
    method: 'google-authenticator',

    app_logo: themeConfig.app.logo,
    arrow_left_icon: mdiChevronLeft,
    mdiHelp,
  }),
  methods: {
    activateTwofactor: function () {
      this.code.error = ''
      this.secondary_email.error = ''
      console.log(this.secondary_email)
      this.loading = true

      this.$http
        .post('2fa/activate', {
          code: this.code.model,
          secondary_email: this.secondary_email.model,
          method: this.method,
          secret: this.secret,
        })
        .then(res => {
          this.$store.commit('user/user', res.data.user)
          this.$router.push('/profile')
        })
        .catch(err => {
          if (err.response.data.error) {
            let errors = err.response.data.error

            this.code.error = errors.code ? errors.code[0] : ''
            this.secondary_email.error = errors.secondary_email ? errors.secondary_email[0] : ''
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    cancel: function () {
      this.$store.commit('user/logout')
      this.$router.push('/login')
    },
    swapMethod: function () {
      this.method === 'google-authenticator' ? (this.method = 'yubikey') : (this.method = 'google-authenticator')
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>

